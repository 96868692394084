<template>
    <transition name="fade">
        <div v-if="showed" class="content_modal_background" @click.self="hideModal">
            <div class="container mt-5">
                <div class="row justify-content-center">
                    <div :class="{['col-10 col-md-8 col-lg-6']: small !== false, ['col-12']: small === false,}">
                        <div class="content_modal d-flex flex-column">
                        <div class="modal-content-box d-flex justify-content-between">
                            <h2 class="m-0 fs-4 fw-normal">
                                <slot name="header">Без заголовка</slot>
                            </h2>
                            <div class="text-muted fw-bold" @click="hideModal" role="button" style="font-size: 1.2rem;">×</div>
                        </div>
                        <div class="modal-content-box pb-5" style="max-height: calc(100vh - 15rem); overflow-y: scroll;">
                            <slot name="body">Нет данных для отображения</slot>
                        </div>
                        <div class="modal-content-box d-flex justify-content-end align-items-center">
                            <slot name="footer"></slot>
                            <button v-if="showCancel !== false" @click="hideModal" class="btn btn-outline-primary ms-2">Отмена</button>
                        </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </transition>
</template>

<script>
export default {
  props: ['showed', 'showCancel', 'small'],
  methods: {
    hideModal() {
      this.$emit('update:showed', false);
    },
  },
};
</script>

<style scoped>
.fade-enter-active, .fade-leave-active {
    transition: opacity .2s;
  }
  .fade-enter, .fade-leave-to /* .fade-leave-active до версии 2.1.8 */ {
    opacity: 0;
  }

  .modal-content-box {
    padding: 1.2rem 1rem;
    border-bottom: 1px solid rgba(0, 0, 0, .1);
  }
  .modal-content-box:last-child {
    border-bottom: none
  }
  .content_modal_background {
    position: fixed;
    z-index: 99;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    background: rgba(0, 0, 0, .6);
  }
  .content_modal {
    background: #fff;
    border-radius: .5rem;
    width: 100%;
  }
  </style>
