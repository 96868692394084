<template>
    <div v-if="admin" class="menu-left">
        <div class="menu-left-item">
            <router-link to="/clients"><img class="logo-image" src="@/assets/logo-icon.svg" alt=""></router-link>
        </div>
        <div class="menu-left-item" :class="{'active': pageName.startsWith('/clients')}">
            <router-link to="/clients"><DomainIcon :size="20"/></router-link>
        </div>
        <div class="menu-left-item" :class="{'active': pageName.startsWith('/users')}">
            <router-link to="/users"><AccountIcon :size="20" /></router-link>
        </div>
        <div class="menu-left-item" @click="$emit('logoutEvent')"><LogoutIcon :size="20"/></div>
    </div>

    <div v-else class="menu-left">
        <div class="menu-left-item" :class="{'active': pageName == '/'}">
            <router-link to="/"><img class="logo-image" src="@/assets/logo-icon.svg" alt=""></router-link>
        </div>
        <div class="menu-left-item" :class="{'active': pageName.startsWith('/project')}">
            <router-link to="/projects"><LayersIcon :size="20"/></router-link>
        </div>
        <div class="menu-left-item" :class="{'active': pageName.startsWith('/news')}">
            <router-link to="/news/offers"><NewsIcon :size="20"/></router-link>
        </div>
        <div class="menu-left-item" @click="$emit('logoutEvent')"><LogoutIcon :size="20"/></div>
    </div>
</template>

<script>
// https://pictogrammers.com/library/mdi
import AccountIcon from 'vue-material-design-icons/AccountMultiple.vue';
import DomainIcon from 'vue-material-design-icons/Domain.vue';

import LayersIcon from 'vue-material-design-icons/Layers.vue';
import NewsIcon from 'vue-material-design-icons/NewspaperVariantMultipleOutline.vue';

import LogoutIcon from 'vue-material-design-icons/Logout.vue';

export default {
  components: {
    AccountIcon, DomainIcon, LayersIcon, NewsIcon, LogoutIcon,
  },
  props: ['pageName'],
  data() {
    return {
      admin: false,
    };
  },
  created() {
    this.admin = localStorage.admin == '1';
  },
};
</script>
<style scoped>
.logo-image {
    width: 2rem;
}
.menu-left {
    background-color: #eee;
    display: flex;
    flex-direction: column;
    width: 3rem;
    height: 100%;
}
.menu-left-item  {
    width: 100%;
    text-align: center;
    height: 3rem;
    line-height: 3rem;
    cursor: pointer;
    transition: background-color .2s;
}
.menu-left-item.active, .menu-left-item:hover {
    background-color: rgba(255, 255, 255, 0.75);
}
.menu-left-item a {
    display: block;
    color: #000;
}
a {
    color: #000 !important
}

</style>
