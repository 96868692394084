<template>
    <div>
        <div class="bread-crumbs-block d-flex justify-content-between">
            <div>
                <span class="bread-crumbs-item">
                    <router-link v-if="pathLength != 0" to="/">Личный кабинет</router-link>
                    <span v-else>Личный кабинет</span>
                </span>
                <span v-for="(val, key, index) in path" class="bread-crumbs-item">
                    <router-link v-if="index != pathLength-1" :to="key">{{val}}</router-link>
                    <span v-else>{{val}}</span>
                </span>
            </div>
            <div id="bread-crumbs-right"></div>
        </div>
    </div>

</template>
<script>
export default {
  computed: {
    pathLength() {
      return Object.keys(this.path).length;
    },
    path() {
      const { path } = this.$route;
      if (path == '/') return {};
      if (path == '/project') {
        return {
          '/projects': 'Договоры',
          '/project': 'Договор',
        };
      }
      return {
        [`/${this.$route.path}`]: this.$route.meta.title,
      };
    },
  },
};
</script>
<style scoped>
.bread-crumbs-block {
    background-color: #f5f5f5;
    padding: .25rem .5rem;
    font-size: .8rem;
    border-radius: 0 0 .25rem .25rem;
    color: #666
}
.bread-crumbs-item::after {
    content: '/';
    padding: 0 .35rem;
}

.bread-crumbs-item:last-child::after {
    content: '';
}

</style>
