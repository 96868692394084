<template>
    <div>
        <BaseBreadCrumbs/>
        <!-- <div>version</div> -->
    </div>
</template>
<script>
import BaseBreadCrumbs from '@/components/BaseBreadCrumbs.vue';

export default {
  components: { BaseBreadCrumbs },
};
</script>
<style scoped>
</style>
