import Vue from 'vue';
import VueRouter from 'vue-router';

Vue.use(VueRouter);

const DEAULT_TITLE = 'Личный кабинет | OOO «Лизинг-Медицина»';

const routes = [
  {
    path: '/',
    name: 'home',
    component: () => import('../views/user/HomeView.vue'),
    meta: {
      showLeftMenu: true,
      title: 'Главная',
    },
  },
  {
    path: '/login',
    name: 'login',
    component: () => import('../views/admin/LoginView.vue'),
    meta: {
      showLeftMenu: false,
      title: 'Вход',
    },
  },
  {
    path: '/users',
    name: 'users',
    component: () => import('../views/admin/UsersView.vue'),
    meta: {
      showLeftMenu: true,
      title: 'Пользователи',
    },
  },
  {
    path: '/clients',
    name: 'clients',
    component: () => import('../views/admin/ClientsView.vue'),
    meta: {
      showLeftMenu: true,
      title: 'Клиенты',
    },
  },

  {
    path: '/signin',
    name: 'login',
    component: () => import('../views/user/SigninView.vue'),
    meta: {
      showLeftMenu: false,
      title: 'Вход',
    },
  },
  {
    path: '/projects',
    name: 'projects',
    component: () => import('../views/user/ProjectsView.vue'),
    meta: {
      showLeftMenu: true,
      title: 'Договоры',
    },
  },
  {
    path: '/project/:tab/:id',
    name: 'project',
    component: () => import('../views/user/ProjectPageView.vue'),
    meta: {
      showLeftMenu: true,
      title: 'Договоры',
    },
  },
  {
    path: '/news/:tab',
    name: 'news',
    component: () => import('../views/user/NewsPageView.vue'),
    meta: {
      showLeftMenu: true,
      title: 'Новости',
    },
  },
];

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
});

router.afterEach((to, from) => {
  Vue.nextTick(() => {
    let res;
    if (to.meta.title) {
      res = `${DEAULT_TITLE} | ${to.meta.title}`;
    } else {
      res = DEAULT_TITLE;
    }
    document.title = res;
  });
});

export default router;
