<template>
  <div class="h-100">
    <!-- Модальное окно выхода -->
    <BaseModal :showed.sync="modalLogoutShowed" :showCancel="false">
      <!-- заголовок модалки -->
      <template v-slot:header>Выход из системы</template>

      <!-- тело модалки -->
      <template v-slot:body>Вы действительно хотите выйти?</template>

      <!-- подвал модалки -->
      <template v-slot:footer>
        <button @click="modalLogoutShowed = false" class="btn btn-outline-primary me-2">Отмена</button>
        <button @click="logoutEvent()" class="btn btn-danger">Выход</button>
      </template>
    </BaseModal>

    <div id="app">
      <div>
        <div class="menu-left-wrapper">
          <BaseLeftMenu v-if="showLeftMenu" @logoutEvent="showLogoutModal" :pageName="pageName"/>
        </div>
        <div style="width: 3rem" v-if="showLeftMenu"></div>
      </div>
      <div style="flex: auto" :class="{['content-padding']: showLeftMenu}">
        <div v-if="showLeftMenu" class="mb-3">
          <BaseTopMenu />
        </div>
        <router-view :class="{['pb-5']: showLeftMenu}"/>
        <div class="text-muted small text-center pb-4" v-if="showLeftMenu">
          Личный кабинет работает в тестовом режиме.<br>В случае несоответствия информации обращайтесь к Вашему менеджеру или координатору.
        </div>

      </div>
    </div>

  </div>
</template>

<script>
import BaseLeftMenu from '@/components/BaseLeftMenu.vue';
import BaseTopMenu from '@/components/BaseTopMenu.vue';
import BaseModal from '@/components/BaseModal.vue';

export default {
  components: { BaseLeftMenu, BaseTopMenu, BaseModal },
  data() {
    return {
      showLeftMenu: false,
      modalLogoutShowed: false,
      pageName: '',
    };
  },
  async mounted() {
    await this.$checkAuthorization();
    this.checkAuth();
  },
  watch: {
    $route(to, from) {
      this.checkAuth();
      this.setShowLeftMenu(to.meta);

      if (to.name != 'project') {
        // fix todo!
        if (document.getElementById('bread-crumbs-right')) {
          document.getElementById('bread-crumbs-right').innerText = '';
        }
      }
    },
  },
  methods: {
    showLogoutModal() {
      this.modalLogoutShowed = true;
    },
    async logoutEvent() {
      let apiResponse;
      if (this.$debug) {
        await this.$func.sleep(500);

        // apiResponse = {
        //   "response": {
        //     "status": 4003,
        //     "message": "Forbidden: restricted access rights or actions with the object."
        //   }
        // }
        // throw Error('12321312')

        apiResponse = {
          response: {
            status: 200,
          },
        };
      } else {
        apiResponse = await this.$http.get(`${this.$hostName}/logout`);
        apiResponse = apiResponse.data;
      }

      if (apiResponse.response.status != 200) {
        throw Error(this.$response.statusMessages.BAD_RESPONSE);
      }

      this.modalLogoutShowed = false;

      this.$router.push(localStorage.admin == '1' ? '/login' : '/signin').catch(() => {});
      localStorage.clear();
    },
    checkAuth() {
      this.pageName = window.location.pathname;
      if (this.pageName != '/') {
        this.pageName = `/${this.pageName.split('/')[1]}`;
      }
      // const avaliablePages = ['/login', '/', '/users', '/clients']
      // const loginUrl = '/login'

      const avaliablePagesUser = ['/', '/signin', '/projects', '/project', '/news'];
      const avaliablePagesAdmin = ['/login', '/users', '/clients'];

      const loginUrlUser = '/signin';
      const loginUrlAdmin = '/login';

      const homeUrlUser = '/';
      const homeUrlAdmin = '/clients';

      const authorized = localStorage.authorized == '1';
      const admin = localStorage.admin == '1';

      const loginUrl = admin ? loginUrlAdmin : loginUrlUser;
      const homeUrl = admin ? homeUrlAdmin : homeUrlUser;

      if (!authorized && [loginUrlUser, loginUrlAdmin].indexOf(this.pageName) != -1) {
        // ok
        return true;
      }

      if (
        (admin && avaliablePagesAdmin.indexOf(this.pageName) == -1)
          || (!admin && avaliablePagesUser.indexOf(this.pageName) == -1)
      ) {
        if (authorized) {
          this.$router.push(homeUrl);
        } else {
          this.$router.push(loginUrl);
        }
      } else if (authorized && this.pageName == loginUrl) {
        this.$router.push(homeUrl);
      } else if (!authorized && this.pageName != loginUrl) {
        this.$router.push(loginUrl);
      }
    },
    setShowLeftMenu(metaData) {
      this.showLeftMenu = metaData.showLeftMenu == true;
    },
  },
};
</script>

<style lang="scss">
@import url('@/assets/css/bootstrap.min.css');
@import url('@/assets/css/main.css');

#app {
  display: flex;
  height: 100%;
}
.menu-left-wrapper {
  width: 3rem;
  position: fixed;
  height: 100%;
}
</style>
