import Vue from 'vue';
import axios from 'axios';
import VueAxios from 'vue-axios';
import App from './App.vue';
import router from './router';

Vue.use(VueAxios, axios);

Vue.config.productionTip = false;

Vue.prototype.$debug = process.env.NODE_ENV === 'development';
Vue.prototype.$isAuthWhenDebug = true;

Vue.prototype.$hostName = 'https://lk.lmed.ru/backend';
Vue.prototype.$checkAuthorization = async function () {
  let authorized = false;
  try {
    const apiResponse = await this.$http.get(`${this.$hostName}/${localStorage.admin == '1' ? 'login' : 'signin'}`);
    const { active } = apiResponse.data.payload;
    authorized = active == true;
  } catch (_) {
    authorized = false;
  }
  localStorage.authorized = authorized || (this.$debug && this.$isAuthWhenDebug) ? '1' : '0';
  return authorized;
};

Vue.prototype.$response = class ResponseStatus {
  static statusCodes = {
    not_started: 0,
    loading: 1,
    error: 2,
    success: 3,
  }

  static statusMessages = {
    CODE_ERROR: 'CODE_ERROR',
    BAD_RESPONSE: 'BAD_RESPONSE',
  }

  constructor() {
    this.statusCode = ResponseStatus.statusCodes.not_started;
    this.statusMessage = '';
  }

  isLoading() { return this.statusCode == ResponseStatus.statusCodes.loading; }

  loading() {
    this.statusCode = ResponseStatus.statusCodes.loading;
    this.statusMessage = '';
  }

  isError() { return this.statusCode == ResponseStatus.statusCodes.error; }

  error(message) {
    this.statusCode = ResponseStatus.statusCodes.error;
    this.statusMessage = message == ResponseStatus.statusMessages.BAD_RESPONSE
      ? message
      : ResponseStatus.statusMessages.CODE_ERROR;
  }

  isSuccess() { return this.statusCode == ResponseStatus.statusCodes.success; }

  success() {
    this.statusCode = ResponseStatus.statusCodes.success;
    this.statusMessage = '';
  }

  isBadResponse() {
    return this.statusMessage == ResponseStatus.statusMessages.BAD_RESPONSE;
  }
};

Vue.prototype.$func = {
  async requestApiData(methodCode, methodAction, cachePeriodInMinutes, ...params) {
    let issetCache = false;
    try {
      const cached = JSON.parse(localStorage[methodCode]);
      issetCache = Date.now() < cached.expired || !cached.data;
    } catch (error) {
      issetCache = false;
    }
    if (issetCache) {
      try {
        return JSON.parse(localStorage[methodCode]).data;
      } catch (error) {
        return {};
      }
    } else {
      try {
        const payload = await methodAction(params);
        localStorage[methodCode] = JSON.stringify({
          expired: Date.now() + cachePeriodInMinutes * 60 * 1000,
          data: payload,
        });
        return payload;
      } catch (error) {
        return {};
      }
    }
  },
  sleep(ms) {
    return new Promise((resolve) => setTimeout(resolve, ms));
  },
  dateFormat(rawFormat, time = true) {
    try {
      const datetime = new Date(Date.parse(rawFormat));

      // 27 мая 2020 г. в 08:54

      let monthName = '';
      switch (datetime.getMonth()) {
        case 0: monthName = 'янв.'; break;
        case 1: monthName = 'фев.'; break;
        case 2: monthName = 'мар.'; break;
        case 3: monthName = 'апр.'; break;
        case 4: monthName = 'мая'; break;
        case 5: monthName = 'июн.'; break;
        case 6: monthName = 'июл.'; break;
        case 7: monthName = 'авг.'; break;
        case 8: monthName = 'сен.'; break;
        case 9: monthName = 'окт.'; break;
        case 10: monthName = 'ноя.'; break;
        case 11: monthName = 'дек.'; break;
      }
      const datePart = `${datetime.getDate()} ${monthName} ${datetime.getFullYear()} г.`;

      if (time) {
        return `${datePart} в ${(`00${datetime.getHours()}`).slice(-2)}:${(`00${datetime.getMinutes()}`).slice(-2)}`;
      }
      return datePart;
    } catch (_) {
      return rawFormat;
    }
  },
  shortDateFormat(rawFormat, time = false) {
    try {
      const datetime = new Date(Date.parse(rawFormat));
      // 27 мая 2020 г. в 08:54

      return `${(`00${datetime.getDate()}`).substr(-2)}.${(`00${datetime.getMonth() + 1}`).substr(-2)}.${datetime.getFullYear()}${
        time ? ` в ${(`00${datetime.getHours()}`).substr(-2)}:${(`00${datetime.getMinutes()}`).substr(-2)}` : ''}`;
    } catch (_) {
      return rawFormat;
    }
  },
  numberFormat(amount) {
    const format = new Intl.NumberFormat('ru-RU', {
      style: 'currency',
      currency: 'RUB',
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    });

    return format.format(amount);
  },
  phoneFormat(rawFormat) {
    const part_1 = rawFormat.slice(0, 1);
    const part_2 = rawFormat.slice(1, 4);
    const part_3 = rawFormat.slice(4, 7);
    const part_4 = rawFormat.slice(7, 9);
    const part_5 = rawFormat.slice(9, 11);

    return `+${part_1} ${part_2} ${part_3}-${part_4}-${part_5}`;
  },
  phoneLinkFormat(rawFormat) {
    return `+${rawFormat}`;
  },
  markText(text, search) {
    search = search.trim().toLowerCase();
    if (search == '') return text;
    let newText = '';

    const searchText = text.toLowerCase();
    const seachTextLength = search.length;

    let idx; let
      prevIdx = -1;
    while ((idx = searchText.indexOf(search, idx + 1)) != -1) {
      const founded = text.slice(idx, idx + seachTextLength);
      newText += `${text.slice(prevIdx == -1 ? 0 : prevIdx + seachTextLength, idx)}<span class="markText">${founded}</span>`;
      prevIdx = idx;
    }
    newText += text.slice(prevIdx == -1 ? 0 : prevIdx + seachTextLength);
    return newText;
  },
  defineLinks(text) {
    const strlen = 100;

    // Ищем ссылки и email адреса в тексте и заменяем их на кликабельные
    const clickableText = text.replace(/(https?:\/\/[^\s]+|www\.[^\s]+|\S+@\S+\.\S+)/g, (match) => {
      if (match.indexOf('http://') === 0 || match.indexOf('https://') === 0 || match.indexOf('www.') === 0) {
        // Если ссылка начинается с "http://", "https://" или "www.", делаем ее кликабельной
        let url = match;
        // Если ссылка начинается с "www.", добавляем протокол
        if (match.indexOf('www.') === 0) {
          url = `http://${match}`;
        }
        // Обрезаем анкор до 100 символов
        const anchor = match.length > strlen ? `${match.substring(0, strlen)}...` : match;
        return `<a href="${url}" target="_blank">${anchor}</a>`;
      }
      // Если это email адрес, делаем его кликабельным
      return `<a href="mailto:${match}">${match}</a>`;
    });
    // Вставляем кликабельный текст в блок
    return clickableText;
  },
  defineIconsUrl(extention) {
    let iconName;
    let fileType;
    switch (extention) {
      case 'pdf':
        iconName = 'pdf';
        fileType = 'PDF';
        break;

      case 'doc':
      case 'docx':
        iconName = 'doc';
        fileType = 'Microsoft Word';
        break;

      case 'xls':
      case 'xlsx':
        iconName = 'xls';
        fileType = 'Microsoft Excel';
        break;

      case 'ppt':
      case 'pptx':
        iconName = 'ppt';
        fileType = 'Microsoft PowerPoint';
        break;

      case 'jpg':
      case 'jpeg':
        iconName = 'jpg';
        fileType = 'Изображение';
        break;

      case 'png':
        iconName = 'png';
        fileType = 'Изображение';
        break;

      case 'svg':
        iconName = 'svg';
        fileType = 'Изображение';
        break;

      case 'ai':
        iconName = 'ai';
        fileType = 'Adobe Illustrator';
        break;

      case 'zip':
        iconName = 'zip';
        fileType = 'Архив';
        break;

      case 'rar':
        iconName = 'rar';
        fileType = 'Архив';
        break;

      default:
        iconName = 'file';
        fileType = 'Файл';
    }
    return {
      address: `${iconName}.svg`,
      type: fileType,
    };
  },
};

new Vue({
  router,
  render: (h) => h(App),
}).$mount('#app');
